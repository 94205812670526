@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700&display=swap');

@import 'variables';
@import 'globals';
@import 'loader';
@import 'ngx-toastr/toastr';
@import 'darkTheme';

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import '~quill/dist/quill.snow.css'; 

// @import 'bootstrap-rtl.scss';

.material-icons {
	font-family: 'Material Icons';
}

.color-primary {
  color: $primary;
}

table {
  td {
    vertical-align: middle;
  }
}

.table-line-hover {
  transition: .3s;
  &:hover {
    background: #e9e9e9;

    td {
      background: transparent;
    }
  }
}

.color-red {
  color: $red;
}

.color-green {
  color: $green;
}