@import "variables";
@import url("https://fonts.cdnfonts.com/css/myriad-web-pro");

html,
body {
  height: 100%;
  width: 100%;
  font-size: 16px;
  letter-spacing: 0.3px;
  line-height: 1.6;
  background: $white;
  font-family: "Myriad Web Pro Regular", sans-serif;

  scroll-behavior: smooth;
}

[tabindex="-1"]:focus {
  outline: none;
}

hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  height: 0;
}

select,
button,
textarea,
input {
  vertical-align: baseline;
}

div {
  box-sizing: border-box;
}

html,
body {
  &[dir="rtl"],
  &[dir="ltr"] {
    unicode-bidi: embed;
  }
}

bdo[dir="rtl"] {
  direction: rtl;
  unicode-bidi: bidi-override;
}

bdo[dir="ltr"] {
  direction: ltr;
  unicode-bidi: bidi-override;
}

img {
  max-width: 100%;
}

a,
a:focus,
a:hover {
  text-decoration: none;
  cursor: pointer;
}

blockquote {
  border-left: 2px solid $gray-200;
  padding-left: 1rem;
  margin-bottom: 1rem;
  font-size: 1.01625rem;
}

.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
  outline: none;
}

.o-hidden {
  overflow: hidden;
}

.separator-breadcrumb {
  margin-bottom: 2rem;
}

@for $i from 1 through 5 {
  .line-height-#{$i} {
    line-height: #{$i};
  }
}

mat-form-field {
  width: 100%;
}

.th {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.center {
  justify-content: center !important;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */

::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handler */

::-webkit-scrollbar-thumb {
  background: $blue3;
}

/* Handler on Hover */

::-webkit-scrollbar-track:hover {
  //background: #555;
}

.boxTemplate {
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 2px;
  padding: 1.7rem;
  background-color: #fff;
}

.divHeaderBtn {
  border-radius: 2px;

  .headerBtn {
    border: 1px solid #333333;
    border-radius: 2px !important;

    @media (max-width: 768px) {
      min-width: 16.2rem !important;
    }
  }
}

.exportBtn {
  color: #a2a1a8;
  border: 1px solid #a2a1a8;
  border-radius: 2px;
  padding: 0.8rem;
  margin-bottom: 0.7rem;
  cursor: pointer;
}

button {
  &.btn {
    font-size: 0.875rem;

    // &.save {

    // }

    &.cancel {
      color: #a2a1a8;
      background-color: #f5f5f5;
      border: 1px solid #a2a1a8;
    }
  }

  &:active {
    background-color: initial;
  }
}

thead {
  background-color: #f5f5f5 !important;

  th {
    background-color: #f5f5f5 !important;

    div {
      background-color: #f5f5f5 !important;
    }
  }
}

.xMarkStyle {
  padding: 0px !important;
  height: 2rem !important;
  width: 2rem !important;
  // margin-bottom: 2.2rem !important;
  transform: translateY(-0.65rem);
  box-shadow: 0px 0px 6px #00000029 !important;
  font-size: 1.2em !important;
}

.radio-inputs {
  position: relative;
  display: flex;
  border-radius: 2px;
  box-sizing: border-box;
  font-size: 0.9rem;
  gap: 0.6rem;
  width: fit-content;
  margin-top: 1.2rem;
  flex-wrap: wrap;

  @media (max-width: 1023px) {
    margin-bottom: 1rem;
  }

  .radio {
    text-align: center;
    border: 1px solid #a2a1a8;
    border-radius: 2px;
    min-width: 5.5rem;

    .name {
      display: flex;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      border-radius: 2px;
      border: none;
      padding: 0.5rem 0.8rem;
      // color: rgba(51, 65, 85, 1);
      transition: all 0.15s ease-in-out;
      height: 100%;

      &:hover {
        background-color: #eeeeee;
      }
    }

    input {
      display: none;

      &:checked + .name {
        color: #fff !important;
        background-color: #1b43a8;
        font-weight: 500;
      }
    }
  }
}

.loaderActions {
  width: 50px;
  padding: 8px;
  margin: auto;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #1b43a8;
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
  @keyframes l3 {
    to {
      transform: rotate(1turn);
    }
  }
}

.mat-mdc-menu-panel {
  min-width: fit-content!important; 
  max-width: 100%!important;

  // @media(max-width: 768px) {
  //   max-width: inherit;
  // }
}

.cdk-overlay-connected-position-bounding-box {
  // overflow-x: auto!important;
  // height: initial!important;

  .cdk-overlay-pane {
    // width: 34.5%!important;
    overflow-x: auto!important;
  }
}

#filters {

  .filtersContent {
    display: flex;
    gap: 1.25rem;
  }

  .filter {
    p {
      font-size: 0.875rem;
      color: #5E5E5E;
    }

    mat-form-field {
      height: 3.5rem;
    }
  }
}

.mat-mdc-menu-panel {
  border: 0.5px solid #E8E8E8;
}

full-calendar {
  table {
    .fc-col-header {
      width: 100% !important;
    }
  }

  .fc-daygrid-body {
    width: 100% !important;

    table {
      width: 100% !important
    }
  }
}

// .mat-input-element {
// 	caret-color: $primary;
// }

// .mat-form-field-appearance-outline.mat-focused.mat-form-field-outline-thick {
// 	color: $primary !important;
// }

// .mat-flat-button.mat-primary {
// 	background-color: $primary;
// 	color: $white;
// }

//calendario css/*
/*full-calendar {

  th > .fc-scrollgrid-sync-inner {
    background-color: #45a049!important;
  }
  
  td {
    height: 6rem!important;
  }
  
  td > .fc-daygrid-day-frame.fc-scrollgrid-sync-inner {
    background-color: #a7e!important;
    height: 1rem!important;
    max-height: 6rem!important;
    min-height: 1px!important;
  }
} */

//css para o calendario
@media (max-width:600px){
  .fc .fc-toolbar {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap-reverse;
    gap:6px;
  }

  .fc-toolbar-chunk:nth-child(2) {
    order: 1;
    margin-top:1rem;
  }

}

.fc .fc-button-primary {
  color: #a2a1a8 !important;
  border: 1px solid #a2a1a8;
  border-radius: 2px;
  opacity: 1;
  background-color: #FFFFFF !important;
  border-color:#A2A1A8 !important;
}


.fc-day-today {
  background: #1B43A826 !important;
  border: none !important;
  color: #fff;

} 

.fc-event {
  background-color: #2344A3 !important;
  border-color: var(--fc-event-border-color) !important;
  color: white !important; /* Ajuste a cor do texto se necessário */
  font-weight: 500!important;

  .fc-event-title {
  font-weight: 500!important;

  }
}

.fc-day {
  .green{
    background-color:#71D835  !important;
  }
  .blue{
   background-color:  #2344A3 !important;
  }
}


.fc-header-toolbar  {
  
  .fc-toolbar-chunk{
    display:flex;
  }
}

@media (max-width: 800px) {
  .fc-toolbar {
    flex-wrap: wrap; /* Permitir que os itens quebrem em várias linhas */
  }
}
//css do tolltip

.tippy-content{
  border-radius: .125rem;
  background-color: #1B43A8;
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap; /* Impede quebra de linha */
  min-width: fit-content;
  z-index: 1070;
  padding:0 1rem;
  color: #FFFFFF;

 
 
}
.green .tippy-content{
  background-color: #1B43A8;
}
.blue .tippy-content{
  background-color: #71D835;
}

/* Signport theme */
.tippy-tooltip.signpost-theme {
  background-color: #FFFFFF;
  border: 1px solid #EBEBEB;
  height: 200px;
  width: 400px;
  color: black;
  box-shadow: 0 4px 30px lightgray;
}

.tippy-tooltip.signpost-theme .tippy-backdrop {
  background-color: #FFFFFF;
}

.tippy-popper[x-placement^=bottom] .tippy-tooltip.signpost-theme .tippy-arrow {
  border-bottom: 7px solid #FFF;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
}

.tippy-popper[x-placement^=top] .tippy-tooltip.signpost-theme .tippy-arrow {
  border-top: 7px solid #FFF;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
}

.tippy-popper[x-placement^=right] .tippy-tooltip.signpost-theme .tippy-arrow {
  border-right: 7px solid #FFF;
}

.tippy-popper[x-placement^=left] .tippy-tooltip.signpost-theme .tippy-arrow {
  border-left: 7px solid #FFF;
}

/* Clarity signpost theme */
.tippy-tooltip.clarity-theme {
  border: 1px solid #9a9a9a;
  border-radius: .125rem;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  z-index: 1070;
  color: black;
  width: 400px;
  height: 200px;
}

.tippy-tooltip.clarity-theme .tippy-backdrop {
  background-color: #FFFFFF;
}

.tippy-popper[x-placement^=bottom] .tippy-tooltip.clarity-theme .tippy-arrow {
  border-bottom: 7px solid #FFF;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
}

.tippy-popper[x-placement^=top] .tippy-tooltip.clarity-theme .tippy-arrow {
  border-top: 7px solid #FFF;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
}

.tippy-popper[x-placement^=right] .tippy-tooltip.clarity-theme .tippy-arrow {
  border-right: 7px solid #FFF;
  border-top: 7px solid transparent;
}

.tippy-popper[x-placement^=left] .tippy-tooltip.clarity-theme .tippy-arrow {
/*   border-left: 7px solid ; */
  border-top: 7px solid transparent;
}



