$font-family-heading: 'Open Sans', sans-serif;
$font-family-base: 'Open Sans', sans-serif;
$font-size-base: 0.813rem;

// GLOBAL COLORS
$facebook: rgb(55, 101, 201);
$google: rgb(236, 65, 44);
$twitter: rgb(3, 159, 245);

$blue: #000c8f !default;
$indigo: #6610f2 !default;
$pink: #e83e8c !default;
$red: #f50000 !default;
$orange: #FF9B3E !default;
$yellow: #F7CD60 !default;
$green: #69C669 !default;
$teal: #20c997 !default;
$cyan: #9c27b0 !default;
$purple: #41228e !default;
$purpleDark: #2e1865 !default;
$purpleLight: #542cb7 !default;
$purpleLighten: #6d44d2 !default;

$branded1: #2344A3;
$branded2: #71D835;
$branded3: #94cef2;
$branded4: #262626;
$branded5: #95aabf;
$branded6: #202B38;

$brand: #666666;

$dark: #3F3F3F;
$dark1: #262626;
$dark2: #3F3F3F;
$dark3: #666666;

$grey: #AEB0B2;
$grey1: #AEB0B2;
$grey2: #d9d9d9;
$grey3: #f7f7f7;
$grey4: #ecebe9;
$grey5: #d4d4d4;
$grey6: #B4AEA6;

$backgroun-base: #e6e6e6;

$primary-base: $branded1;

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #eee !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #bbb !default;
$gray-600: lighten(desaturate($primary-base, 40), 4%) !default;
$gray-700: darken(desaturate($primary-base, 40), 0%) !default;
$gray-800: darken(desaturate($primary-base, 40), 8%) !default;
$gray-900: darken(desaturate($primary-base, 40), 12%) !default;
$black: darken($primary-base, 60%) !default;

$primary: $branded1 !default;
$secondary: $branded2 !default;
$success: $primary !default;
$info: $blue !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-500 !default;
$dark: $gray-900 !default;
$background: $backgroun-base !default;
$foreground: $gray-900 !default;
$heading: darken($foreground, 8%);

$colors: (
        'blue': $blue,
        'indigo': $indigo,
        'purple': $purple,
        'pink': $pink,
        'red': $red,
        'orange': $orange,
        'yellow': $yellow,
        'green': $green,
        'teal': $teal,
        'cyan': $cyan,
        'white': $white,
        'gray': $gray-600,
        'gray-dark': $gray-800
) !default;

$theme-colors: (
        primary: $primary,
        secondary: $secondary,
        success: $success,
        info: $info,
        warning: $warning,
        danger: $danger,
        light: $light,
        gray-100: $gray-100,
        gray-200: $gray-200,
        gray-300: $gray-300,
        gray-400: $gray-400,
        dark: $dark
) !default;

/**
BUTTONS
*/
$btn-primary-color: $white;
$btn-outline-secondary-color-hover: $branded2;
$btn-cancel-color: $grey6;
$btn-cancel-background-color: $grey4;
$btn-cancel-background-color-hover: $grey5;

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1170px
) !default;

// LAYOUT VARS
$topbar-height: 60px;
$topbar-height-mobile: 70px;

// sidebar background
$sidebar-width: 260px;
$sidebar-min-width: 62px;
$sidebar-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
$sidebar-background: #F3F4F5;
$sidebar-background-hover: #e5e5e5;
$sidebar-background-active: $secondary;
$sidebar-border-radius: 6px;

// sidebar color text
$sidebar-text-color: $white;
$sidebar-text-active-color: $primary;
$sidebar-text-hover-color: $white;

// sidebar color icon
$sidebar-icon-color: $primary;
$sidebar-icon-active-color: $primary;
$sidebar-icon-hover-color: $primary;

// background color square login
$squareSecondary: rgba(113, 216, 53, 0.68);

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins/_breakpoints';

// Dark Theme

$black1: #222222;
$black2: #0D0D0D;
$black3: #000000;
$black5: #212126B3;
$black6: #141414;
$black7: #212126;
$black4: rgba(0, 0, 0, 0.16); 
$blue1: #819EE8;
$blue2: #4F7EF3;
$blue3: #1A66FF;
$blue4: #1650E2;
$blue5: #1B43A8;
$blue6: #0C1F4E;
$white1: #FFFFFF;
$white2: #E8E8E8;
$green1: #71D835;
$green2: #00D071;
$green3: #1F9E51;
$green4: #32BCAD;
$gray1: #A2A1A8;
$gray2: #787878;
$gray3: #212121db;
$gray4: #3d3d3dff; 
$red1: #FF2D00;
$red2: #C5381A;


// Cores FlifPix
$login: #71D835;
$login2: #2344A3; 
$header: #1B43A8;
$tela: #1B43A8;
$sidebar-options-active: #1B43A8 ;
$bottons: #1B43A8;
$person-botton:#1B43A8;
$textbutton:#FFFFFF;
$white:#FFFFFF;
$areavalidation:#1B43A8;
$sidebar-options-active-text: #1B43A8 ;
$sidebar-options-active-area: #dde3f2ff;
$sidebar-options-text-active:#1B43A8;
$title-system:#1B43A8;
$corFonte:#FFFFFF; 
$black: #000000;
$dashboard-account: #e8ecf6ff;
$dashboard-account-predicted:rgb(129, 158, 232);
$dashboard-account-overdue: rgb(197, 56, 26);
$dashboard-account-receveid:  rgb(27, 67, 168);
$header-item-botton:#1B43A8;
$header-item-bottom-background:#abbce755;
$report-icon:#1B43A8; 
$settings-private:#1B43A8;
$houverItems:#1B43A8;
$create-business-bottom:#1B43A8;
$create-business-bottom-text:#ffffff;


