// Dark Theme Classes

.dark_gray3 {
  background-color: $gray3 !important;
}
.black_1 {
  background-color: $black1 !important;
}
.font-white {
  color: $white1 !important;
}
.black_2 {
  background-color: $black2 !important;
}

// Tema Dark -- estrutura baseado no layout-private
.dark-theme {

  .radio-inputs {
    .radio {
      .name:hover {
        background-color: $settings-private !important;
        color: white;
      }
    }
  }

  #mainHeader {
    background-color: $black7;
    // Divisão
    .user-info {
      background-color: $black7;
      color: $white1;
    }
    .themeBtns {
    }
    i {
      color: white !important;
    }
    /*  .animate {
      background-color: $black2;
    } */

    .dropdown-menu.animate.show {
      background-color: $black7 !important;

      li {
        a {
          color: white !important;
        }
        &.animate {
          color: $white !important;

          &:nth-last-child(1) {
            color: initial !important;
          }
        }
      }

      .themeSelector {
        p {
          color: $white;
        }
      }
    }
    .dropdown-mobile {
      .animate {
        background-color: $black7 !important;
        color: $white !important;
      }
    }
  }

  #contentTemplate {
    // Divisão

    background-color: $black6;

    #sidebarTemplate {
      // Divisão
      #sidebar {
        background-color: $black7;
        a {
          &.active {
            color: $white1;
            background-color: $gray4 !important;
            position: relative;

            i {
              color: $white1;
            }
            &::before {
              content: "";
              position: absolute;
              top: 0;
              right: 0;
              width: 2px;
              height: 100%;
              background-color: $white1 !important;
            }
          }
          &:hover {
            color: $white1;
            background-color: $gray4;
            i {
              color: $white1;
            }
          }
        }
        .dropdown-menu {
          background-color: $black1;
        }
      }
    }

    #content {
      .integrations {
        .integrationTemplate {
          .card-app {
            background-color: #212126ff !important;
            border: 1px solid $gray4;
            b {
              color: white !important;
            }

            h2 {
              color: white !important;
            }

            .actions {
              color: $white;
            }
          }
        }
      }

      .settingsTemplate {
        #tabs {
          background-color: $black1;

          .tabs-inputs {
            background-color: #141414ff !important;
            .radio {
              input {
                &:checked + .name {
                  background-color: #212126ff !important;
                  color: $blue3;

                  &:hover {
                    color: #129;
                  }
                }

                + .name:hover {
                  color: #414d75;
                }
              }

              .name {
                color: $white2;
                background-color: #141414ff !important;
              }
            }
          }
        }

        .content {
          background-color: #212126ff !important;
          ngx-dropzone {
            background-color: #212126ff !important;
          }

          h1 {
            color: $white1;
          }

          h2 {
            color: $white2;
          }

          h3 {
            color: $white2;
          }

          p {
            color: $white2 !important;
          }

          #generalCancel {
            background-color: #141414ff !important;
            border: 1px solid $sidebar-options-active-text !important;
          }

          .valueInput {
            color: $white2;
          }

          .image-container {
            .bg-preview {
              background-color: transparent;
            }
          }
        }
      }
      // Divisão
      background-color: $black6;
      //componente
      #header {
        // Divisão
        // background-color: $black1;

        .title {
          // Divisão
          color: $white1;
        }

        .divHeaderBtn {
          color: $white;

          button {
            border-color: $white;
          }
        }
      }

      .boxTemplate {
        // Divisão
        background-color: $black7;
      }

      .mat-mdc-paginator-outer-container {
        background-color: $black7 !important;
        margin-top: white1 !important;
      }

      //profile
      #tour-start {
        .titleESubtitle {
          .user-title {
            color: $white1 !important;
          }
          .user-subtitle {
            color: $white1 !important;
          }
        }
        mat-card {
          background-color: $black7 !important;
          box-shadow: 0px 3px 6px #00000029;

          border-radius: 2px;
          opacity: 1;
          mat-card-title {
            color: $white2;
          }
          span {
            color: $white1;
          }
        }
      }
      //perfil
      #perfil {
        .title {
          color: $white1;
        }
        .formulary {
          background-color: #212126ff;
        }
        .botaoCancelar {
          background-color: $black1;
          color: $white1;
        }
      }
      //dashboard
      .dashboard-container {
        background-color: $black5;
        .title {
          color: $white1;
        }
        .title-graphic {
          color: $white1;
        }
        .dashboard-card {
          background: #212126 0% 0% padding-box;
          border: 0.20000000298023224px solid #707070;
          border-radius: 5px;
          opacity: 1.5 !important;
          color: $white1;
          .card-smaller {
            background: #212126 0% 0% padding-box;
            border: 1px solid #707070;
            opacity: 1;
          }
        }
        .value-category {
          color: white;
        }

        .accounts {
          .account {
            &.blue {
              background: $blue5!important;
            }
            &.yellow {
              background: $gray2;
            }
          }
        }

        .deposits {
          .predicted {
            background-color: $dashboard-account-predicted !important;
          }

          .overdue {
            background-color: $dashboard-account-overdue !important;
          }

          .received {
            background-color: $dashboard-account-receveid !important;
          }
        }

        .withdraws {
          .predicted {
            background-color: $dashboard-account-predicted !important;
          }

          .overdue {
            background-color: $dashboard-account-overdue !important;
          }

          .received {
            background-color: $dashboard-account-receveid !important;
          }
        }

        .acessibility {
          .predicted {
            background-color: $dashboard-account-predicted !important;
          }

          .overdue {
            background-color: $dashboard-account-overdue !important;
          }

          .received {
            background-color: $dashboard-account-receveid !important;
          }
        }

        .skeleton-loader {
          background-color: $black1;
        }

        .gateways {
          .card-body-gateway {
            background-color: $blue5;
            color: $white;

            .card-title {
              color: $white !important;
            }

            .value-gateway {
              color: $white !important;
            }

            .sub-value-gateway {
              color: $white !important;
            }

            .delta.positive {
              color: $green1 !important;
            }
          }
        }
      }
    }
  }

  mat-dialog-container {
    .mat-mdc-dialog-surface.mdc-dialog__surface {
      background-color: #212126ff;
      border: 0.5px solid gray;

      .inputEnd {
        .input-container {
          input {
            background-color: $black2;
            color: $white1;
          }
        }
      }
    }

    h1 {
      color: $white !important;
    }

    p {
      color: $white1 !important;
    }

    .permissions {
      label {
        color: $white1 !important;
      }
    }

    #actionsDialog {
      #cancel {
        background-color: $black1 !important;
        color: $white1 !important;
      }
    }

    .textAlert {
      color: $white;
    }
  }

  .mat-mdc-menu-panel {
    background-color: #212126ff !important;

    #formFilters {
      #actionsDialog {
        #cancel {
          background-color: $black1 !important;
          color: $white1 !important;
          border-bottom: 0.5px solid $gray1;
        }
      }
    }
  }

  .button_status {
    &.pending {
      background-color: #edc001 !important;
      color: #333333 !important;
    }

    &.paid {
      background-color: #a3e7ce !important;
      color: #333333 !important;
    }

    &.error {
      background-color: #ff0000 !important;
      color: #333333 !important;
    }
  }
  //create-business
  #tour-start {
    .business-title {
      color: $white1;
    }
    .formulary {
      background-color: $black2;

      .buttonCancel {
        background-color: $black1;
        color: $white1 !important;
      }
      .ng2FileDrop {
        background-color: $black1;
      }
    }
  }

  //fast receipt
  .fast-receipt {
    .headerFastPix {
      color: $white1 !important;
    }
    button {
      color: white !important;
    }
    .sub_headerFastPix {
      color: $white1 !important;
    }
    .card_Bank {
      .row {
        background-color: #212126ff !important;
        .nameBank {
          color: $white1 !important;
          font-weight: 400;
        }
      }
    }
    .card-information {
      background-color: #212126ff !important;
    }
    .formularyValue {
      background-color: #212126ff !important;
    }
  }
  //agendamento calendario
  #calendar-container {
    color: $white1 !important;

    full-calendar {
      button {
        color: $white2 !important;
        background-color: $black2 !important;
      }
      /*  .fc-scrollgrid-sync-inner{
        background-color: $white1!important;
      }

      td {
        .fc-day fc-day-sun fc-day-past fc-day-other {
          border:1px solid ;
        }
      }
       */

      /*  .fc-daygrid-day-frame {
        background-color: $white1 !important;
      } */
      .fc-popover-header {
        background-color: $black7;
      }
      .fc-popover-body {
        background-color: $black7;
      }
      table {
        border-left-color: #696969 !important;
        .fc .fc-scrollgrid {
          border-color: #696969 !important;
        }
      }
      table tbody tr td {
        border-color: #696969 !important;
        .fc-daygrid-day-number {
          color: #f5f5f5 !important;
        }
      }
      .fc-day-today {
        background-color: #696969 !important;
        opacity: 1;
      }
      th {
        .fc-col-header-cell-cushion {
          color: #f5f5f5 !important;
        }

        div.fc-scrollgrid-sync-inner {
          border-left: 1px solid;
        }
      }
    }
  }

  .button_active {
    &.success {
      background-color: #a3e7ce !important;
      color: #333333 !important;
    }
    &.error {
      background-color: #ff0000 !important;
      color: #333333 !important;
    }

    &.valid {
      background-color: rgba(113, 216, 53, 1);
      color: rgb(0, 84, 51);
    }
  }

  .xMarkStyle {
    color: $white !important;
    background-color: black1 !important;
  }

  .btnAttachment {
    background-color: $black1 !important;
    color: $white1 !important;
  }

  .mat-mdc-menu-content {
    .mat-mdc-menu-item {
      border-bottom: 0.5px solid #fff;

      &:last-child {
        border-bottom: none;
      }
    }
  }

  .mat-mdc-card {
    background-color: #212126ff !important;

    &.report_card {
      border: 0.5px solid gray !important;
      background-color: #212126 !important;
      .report_info {
        .mat-mdc-card-title {
          color: #ffff !important;
        }

        .mat-mdc-card-content {
          color: $white1 !important;
        }
      }
    }
  }

  table {
    thead {
      th {
        background-color: $black6 !important;

        div {
          color: $white1 !important;
          background-color: $black6 !important;
        }
      }
    }

    tbody {
      tr {
        border-color: $black2 !important;

        td {
          color: $white2 !important;
          background-color: $black7 !important;
          border-color: $white !important;
        }
      }
    }

    tfoot {
      tr {
        td {
          background-color: $black1 !important;
          color: $white1;
        }
      }
    }

    &.split_pix_deposits {
      tbody {
        tr {
          border-color: $black1 !important;
          td {
            color: $white2 !important;
            background-color: $black1 !important;
            border-color: $white !important;
          }
        }
      }
    }
  }

  .value-category {
    color: #ffff !important;
  }
  .status {
    color: black !important;
  }
  .card {
    background-color: $black5 !important;
  }

  //tela do onboarding fligbank
  .main {
    background-color: #212126ff !important;
    .title {
      color: $white !important;
    }
    .subtitle {
      color: $white1 !important;
    }
    .cancel-button {
      background: #212126ff !important;
    }
  }
  //tela paginda de depositos
}

//Fligpix
